import { Route, Switch } from "react-router-dom";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import Signup from "./pages/Signup";
import FAQ from "./pages/FAQ";
import ContactUs from "./pages/ContactUs";
import Terms from "./pages/Terms";
import Store from "./Store";

function App() {
    return (
        <Store>
            <div>
                <ResponsiveAppBar />
                <Switch>
                    <Route exact from="/faq" render={props => <FAQ {...props} />} />
                    <Route exact from="/contact" render={props => <ContactUs {...props} />} />
                    <Route exact from="/termsofservice" render={props => <Terms {...props} />} />
                    <Route from="/" render={props => <Signup {...props} />} />
                </Switch>
            </div>
        </Store>
    );
}

export default App;