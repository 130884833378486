import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

const ThreeSteps = () => {
    return (
    <Grid container direction="row" alignItems="center">
        <Grid container item direction="column" alignItems="center" rowGap={3}>
            <Divider sx={{width:'100%'}}/>
            <Grid container direction="row" alignItems="center" wrap="nowrap" justifyContent="center">
                <Grid item>
                    <PhotoCameraIcon fontSize="large"/>
                </Grid>
                <Grid item>
                    <Typography sx={{whiteSpace: 'pre-line'}}>Take a picture of your Costco receipt and text it to us</Typography>
                </Grid>
            </Grid>

            <Grid container direction="row" alignItems="center" wrap="nowrap" justifyContent="center">
                <Grid item>
                    <NotificationsActiveIcon fontSize="large"/>
                </Grid>
                <Grid item>
                    <Typography sx={{whiteSpace: 'pre-line'}}>Get a text when your item goes on sale</Typography>
                </Grid>
            </Grid>
            
            <Grid container direction="row" alignItems="center" wrap="nowrap" justifyContent="center">
                <Grid item>
                    <DirectionsWalkIcon fontSize="large"/>
                </Grid>
                <Grid item>
                    {/* <Typography sx={{whiteSpace: 'pre-line'}}>Walk into Costco and claim your partial refund</Typography> */}
                    <Typography sx={{whiteSpace: 'pre-line'}}>Walk into Costco and get your money back</Typography>
                </Grid>
            </Grid>

            <Divider sx={{mb: 2, width:'100%'}}/>
        </Grid>
    </Grid>
    );
}

export default ThreeSteps;