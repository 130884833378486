import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';
import Divider from '@mui/material/Divider';
import BulkTerms from './BulkTerms';

const IntroTextBody = `And as fellow human beings, we think you deserve to know exactly what you can expect from your Refundio experience. Refundio works by finding you Costco deals and discounts that apply to items you've recently purchased (usually within 30 days). When you buy something in a physical costco warehouse, and you share your receipt with us, we will attempt to track each of the items on your receipt, monitoring each of those items to see if they ever reduce in price. We only do this for in-warehouse purchases and we currently do not handle any online purchases.  

So how do we use your data?

Once you send us the picture of your Costco receipt, we analyze that image to identify pieces of information that help us get you discounts, such as purchase date, item name, and item price among other things.

Here's an example:
Since these discounts are on items that you already purchased, you get compensated by going back into the Costco warehouse and receiving a "partial refund" for what you bought. For example, if you buy a box of cereal for $13.00 and then it goes on sale 3 weeks later with a price of $10.00 (instead of $13.00), then you should be entitled that the difference of the original price and the reduced price (in this case, $3.00). You simply need to walk into a Costco warehouse to claim that reward. Costco staff will usually ask you if you want to be paid in cash or have the partial refund applied to your original form of payment. It's your choice. Refundio sends you a text message when it learns about a discount on one of the items it knows you purchased within a 30-day window.

Our stance on privacy is simple: We will be transparent with what data we collect and how we use it to help try and save you time and money, and you can decide if you're good with that. If this sounds fair, then let's start shopping smarter, together.

P.S. If you have any questions or comments, you can reach out to us by sending us a message on our Contact Us page.
-The Refundio Team`;

const IntroPrivacy = `This privacy policy (“Privacy Policy”) and the following terms of service (“Terms of Service”) explains how Refundio (“Refundio” or “we”) collects and processes information from users (“Users” or “User”) of the website, software applications, and other SMS text-message-based services we provide (collectively “Refundio's products”). Refundio's products include, but are not limited to, the Refundio SMS text-message-based service (the “Texting Service”), the Refundio mobile application (the “Mobile App”), and the website located at https://refnd.me (the “Website”). If we ever need additional personal information for a specific reason, we'll ask for your permission. Also, to the extent that Refundio has links or associations to other companies, please note that we do not own, operate, or control them, and they have their own controlling privacy policies that you should review.`;

const DataCollect = `In order to provide our products, Refundio collects information that you share directly with us when you use our Texting Service, Website, or Mobile App. Your account is set up only after you make initial contact with us. In order to make this account, we will use information that you provide us. This includes the phone number from which you messaged us as well as potentially all of the information listed on the Costco receipt which you send us. 

As you continue to use Refundio's products, you might also provide information when you:
- send us a text message
- reach out to us via our Contact Us page
- refer friends to Refundio
- communicate with Refundio through email or other means

The Refundio Texting Service, Mobile App, or other products may offer the opportunity to send monetary contributions to Refundio, such as through a Venmo request as well as the opportunity to save your payment information in order to allow you to avoid entering that information again at some point in the future. In those cases, you will be asked to input information about that payment option, as well as information needed to complete those purchases.`;

const DataNoCollect = `We collect information that we believe can help us save our users time and money. This does not include, and we do not collect, any information from your emails or from websites that are not associated with Refundio. We also do not collect bank or credit card information (unless you expressly provide those to us).`;

const ShareData = `We know how important your personal data is to you, so we do not sell it. We'll only share it in ways you'd expect (as we explain here). That means we will share your data, if needed to facilitate your partial refunds, with businesses who help us operate Refundio, or if we are legally required to do so.

We may share information with our service providers (businesses that work for and with us) for the purpose of running Refundio and fulfilling our commitments to you. These include businesses that perform services on our behalf, including to help us maintain our products, to provide support for legal, banking, security protection, payment processing, our own marketing, and customer service. Please know that when we share information with our service providers, it's for the purposes outlined in this Privacy Policy and not for their independent use.

We may also share information in the following cases:
- with your express consent;
- with our affiliates and subsidiaries, but only for purposes allowed by this Privacy Policy;
- in an aggregate or anonymized format that does not identify any specific person;
- as required by law, or to comply or respond to a valid government request;
- when we believe in good faith that it's necessary to protect our rights, protect your safety or the safety of others, or investigate fraud; and
- with a buyer or successor if Refundio is involved in a merger, acquisition, or similar corporate transaction.`;

const ProtectData = `The security of your information is important to us. We are dedicated to protecting your information and we have put in place physical, electronic, and procedural safeguards.

These measures include limiting access, using encryption, testing for vulnerabilities, advanced malware detection, employing pseudonymization and anonymization techniques, and more.

Though we hate to say it, despite our efforts, we can't guarantee that user information will not be accessed, viewed, disclosed, altered, or destroyed as a result of a breach of any of our safeguards. You provide us with information at your own risk.`;

const Minors = `We created Refundio for the exclusive use of adults (18 and older). We don't knowingly collect or solicit personal information from children. If you are a child under 18, please do not attempt to use or register for Refundio's products or send any personal information to us.

If we learn we have collected personal information from someone under 18, we will delete that information as quickly as possible. If you are a parent or guardian of a child who you think may have given us some personal information or posted information on any public portion of Refundio's products, please contact us. We'll help you remove it.`;

const Note = `Our Company is based in the United States. The Services are controlled and operated by us from the United States and are not intended to subject us to the laws or jurisdiction of any state, country or territory other than that of the United States. Your Personal Information may be stored and processed in any country where we have facilities or in which we engage service providers, and by using the Services you consent to the transfer of information to countries outside of your country of residence, including the United States, which may have data protection rules that are different from those of your country. In certain circumstances, courts, law enforcement agencies, regulatory agencies or security authorities in those other countries may be entitled to access your personal information.`;

const Terms = () => {
    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography variant="h4" sx={{ textAlign: 'center', m: 1 }}>Terms of Service & Privacy Policy</Typography>
                <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>{IntroTextBody}</Typography>
                <Divider sx={{my: 2}}/>

                <Typography variant="h5" sx={{ textAlign: 'center', m: 1 }}>Introducing the Privacy Policy</Typography>
                <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>{IntroPrivacy}</Typography>
                <Divider sx={{my: 2}}/>

                <Typography variant="h5" sx={{ textAlign: 'center', m: 1 }}>What data we collect and why</Typography>
                <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>{DataCollect}</Typography>
                <Divider sx={{my: 2}}/>

                <Typography variant="h5" sx={{ textAlign: 'center', m: 1 }}>What data we do not collect</Typography>
                <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>{DataNoCollect}</Typography>
                <Divider sx={{my: 2}}/>

                <Typography variant="h5" sx={{ textAlign: 'center', m: 1 }}>How we share your data</Typography>
                <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>{ShareData}</Typography>
                <Divider sx={{my: 2}}/>

                <Typography variant="h5" sx={{ textAlign: 'center', m: 1 }}>How we protect your data</Typography>
                <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>{ProtectData}</Typography>
                <Divider sx={{my: 2}}/>

                <Typography variant="h5" sx={{ textAlign: 'center', m: 1 }}>Minors</Typography>
                <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>{Minors}</Typography>
                <Divider sx={{my: 2}}/>

                <Typography variant="h5" sx={{ textAlign: 'center', m: 1 }}>A note to users outside the United States</Typography>
                <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>{Note}</Typography>
                <Divider sx={{my: 2}}/>

                {/* This is the update from March 1, 2022 */}
                <BulkTerms />
            </CardContent>
        </Card>
    );
}
export default Terms;