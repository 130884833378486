import React, {createContext, useReducer} from "react";

const Reducer = (state, action) => {
    switch(action.type) {
        case 'SET_CODE':
            return {
                ...state,
                referral_code: action.data
            };
        default:
            return state;
    }
};

const initialState = {referral_code: null};

const Store = ({children}) => {
    const [globalState, globalDispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[globalState, globalDispatch]}>{children}</Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;