import React from 'react';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Footer from '../components/Footer';

const FAQ = () => {
    return (
        <div>
            <Typography variant="h5" sx={{ m: 1 }}>How do I sign up?</Typography>
            <Typography variant="body1" sx={{ m: 1 }}>Text the word “start” (without quotes) to (813) 733-6363</Typography>
            <Divider sx={{my: 2}}/>
            <Typography variant="h5" sx={{ m: 1 }}>Can I send more than 1 photo?</Typography>
            <Typography variant="body1" sx={{ m: 1 }}>Yes, you can send many photos, but you have to send them one at a time. You'll receive a confirmation text after every receipt you send. Once you get that confirmation text, you can proceed to sending the next receipt.</Typography>
            <Divider sx={{my: 2}}/>
            <Typography variant="h5" sx={{ m: 1 }}>How long do I have to get my partial refund?</Typography>
            <Typography variant="body1" sx={{ m: 1 }}>When we find a partial refund for you, we will let you know how long you have to claim it (by visiting the store in person). You will typically have less than 30 days.</Typography>
            <Divider sx={{my: 2}}/>
            <Typography variant="h5" sx={{ m: 1 }}>Do I get a refund with every receipt?</Typography>
            <Typography variant="body1" sx={{ m: 1 }}>You will be eligible for a partial refund if at least one of the items that you bought has its price reduced within 30 days. If none of your items’ prices went lower, you won’t have a partial refund to claim.</Typography>
            <Divider sx={{my: 2}}/>
            <Typography variant="h5" sx={{ m: 1 }}>Where do I go to get my partial refund?</Typography>
            <Typography variant="body1" sx={{ m: 1 }}>Go to a Costco membership counter and say “I bought an item that just went on sale and I'd like to be refunded the difference.”</Typography>
            <Divider sx={{my: 2}}/>
            <Typography variant="h5" sx={{ m: 1 }}>What does Refundio do with my data?</Typography>
            <Typography variant="body1" sx={{ m: 1 }}>You can read about our terms of service and privacy policy on the Terms of Service page listed above.</Typography>
            <Divider sx={{my: 2}}/>
            <Typography variant="h5" sx={{ m: 1 }}>Why is there only a 30-day window?</Typography>
            <Typography variant="body1" sx={{ m: 1 }}>The 30 day window is based on Costco's policy</Typography>
            <Divider sx={{my: 2}}/>
            <Typography variant="h5" sx={{ m: 1 }}>Can I send in Costco gasoline receipts?</Typography>
            <Typography variant="body1" sx={{ m: 1 }}>No, Refundio only works for purchases that were made in the Costco warehouse.</Typography>
            <Divider sx={{my: 2}}/>
            <Typography variant="h5" sx={{ m: 1 }}>Can I send in a partial picture of a receipt that is cut off and doesn't show the whole receipt?</Typography>
            <Typography variant="body1" sx={{ m: 1 }}>No, we need to be able to see all 4 corners of the receipt.</Typography>
            <Divider sx={{my: 2}}/>
            <Typography variant="h5" sx={{ m: 1 }}>Does it work with online purchases?</Typography>
            <Typography variant="body1" sx={{ m: 1 }}>No, Refundio only works for purchases that were physically made in the Costco warehouse.</Typography>
            <Footer/>
        </div>
    );
}
export default FAQ;