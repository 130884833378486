import React from 'react';
import Footer from '../components/Footer';

const ContactUs = () => {
    return (
        <div>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScBIMFssVTmmLTWjqbnAxZ_TQitMhDo1KXGCl3zGkuVKTcLOg/viewform?embedded=true" width="100%" height="1700" frameBorder="0" title="Contact Us">Loading…</iframe>
            <Footer/>
        </div>
    );
}
export default ContactUs;