import React, { useContext, useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import QRCode from "react-qr-code";
import Divider from '@mui/material/Divider';
import ThreeSteps from './ThreeSteps';
import Footer from '../components/Footer';
import { Context } from '../Store';

const IntroTextBody = `Most people don't know this but Costco actually has a policy that says they will give you money if an item you recently bought goes on sale. The catch is that you only have 30 days from the date of purchase to collect that money.

Refundio monitors the items that Costco currently has on sale and let's you know how much free money you can get. Just sign up and then send us a picture of your receipt. We track the prices of all of the items displayed on your receipt. Then, when there's a new sale price on one of those items you bought, we'll send you a text with all of the information you need to get your money from Costco's policy.`;

const Signup = (props) => {
    const [state, dispatch] = useContext(Context);
    const [body, setBody] = useState("Start");

    useEffect(() => {
        if (props.location?.pathname) {
            try {
                // The pathname would be: /123XY
                const TempReferralCode = props.location.pathname.substring(1);

                // RegEx pattern to test against
                var regex = /[A-Z1-9]{5}/gm;
                
                // There are 2 conditions we need to check...
                // 1 - The legnth of the referral code should be 5
                // 2 - The referral code should match the RegEx. Exactly 5 chars in A-Z and/or 1-9
                if (TempReferralCode.length === 5 && regex.test(TempReferralCode) === true) {
                    dispatch({type: 'SET_CODE', data: TempReferralCode});
                    setBody(`Start (ref:${TempReferralCode})`);
                } else if (state.referral_code) {
                    setBody(`Start (ref:${state.referral_code})`);
                } else {
                    // Remove the hash from the URL and update the history
                    window.history.replaceState("", document.title, "/");
                }
            } catch (error) {
                console.log(error);
            }
        }
    // eslint-disable-next-line
    }, []);

    const qrText = `SMSTO:+18137336363:${body}`;


    return (
        <Container maxWidth="xl">
            {/* Will be rendered on small screens */}
            <Box sx={{ m: 5, display: { xs: "flex", md: "none" } }}>
                <Grid container direction="row" alignItems="center" spacing={3}>

                    <Grid container item direction="column" alignItems="center">
                        <Typography sx={{whiteSpace: 'pre-line', textAlign: 'center'}}>Send us a text message to get started by clicking the button below!</Typography>
                        <Button variant="contained" href={`sms:8137336363;?&body=${body}`}>Get Started</Button>
                    </Grid>
                
                </Grid>
            </Box>

            {/* Will be rendered on large screens */}
            <Box sx={{ m: 5, display: { xs: "none", md: "flex" } }}>
                <Grid container direction="column" alignItems="center">
                    <Typography>To get started, scan the QR code below with your mobile device. This will generate a text message where you just click send.</Typography>
                    <QRCode value={qrText} />
                    {/* <Logo /> */}
                </Grid>
            </Box>

            {/* Will be rendered on everything */}
            <Box sx={{ m: 5, display: "flex" }}>
                <Grid container item direction="column" alignItems="center">
                    <Grid container item direction="column" alignItems="center" rowGap={6}>
                        <ThreeSteps />
                    </Grid>

                    <Grid container direction="column" alignItems="center">
                        <Typography sx={{whiteSpace: 'pre-line', textAlign: 'center'}}>{IntroTextBody}</Typography>
                    </Grid>
                </Grid>
            </Box>

            {/* Will be rendered on small screens */}
            <Box sx={{ m: 5, display: { xs: "flex", md: "none" } }}>
                <Grid container direction="row" alignItems="center" spacing={3}>

                    <Grid container item direction="column" alignItems="center">
                        <Divider sx={{mb: 2, width:'100%'}}/>
                        <Typography sx={{whiteSpace: 'pre-line', textAlign: 'center'}}>PS, our app is not in the app store. Our users interact with Refundio via text message. No download required.</Typography>
                        <br />
                        <Typography sx={{whiteSpace: 'pre-line', textAlign: 'center'}}>Send us a text message to get started by clicking the button below!</Typography>
                        <Button variant="contained" href={`sms:8137336363;?&body=${body}`}>Get Started</Button>
                    </Grid>
                
                </Grid>
            </Box>
            <Footer/>
        </Container>
    )
};

export default Signup;