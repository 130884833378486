import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import logo from "./logo.png";
import { withRouter } from "react-router-dom";

const ResponsiveAppBar = (props) => {
    const { history } = props;
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleMenuClick = (pageURL) => {
        history.push(pageURL);
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: "flex", md: "none" } }}>
                        <IconButton size="large" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            keepMounted
                            transformOrigin={{ vertical: "top", horizontal: "left" }}
                            open={Boolean(anchorElNav)}
                            onClose={() => setAnchorElNav(null)}
                            sx={{ display: { xs: "block", md: "none" } }}
                        >
                            <MenuItem onClick={() => handleMenuClick("/")}>
                                <Typography textAlign="center">Sign Up</Typography>
                            </MenuItem>
                            {/* https://www.instagram.com/refundio/ */}
                            <MenuItem href="https://www.instagram.com/refundio/" component="a" target="_blank">
                                <Typography textAlign="center">Instagram</Typography>
                            </MenuItem>

                            <MenuItem onClick={() => handleMenuClick("/faq")}>
                                <Typography textAlign="center">FAQ</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuClick("/contact")}>
                                <Typography textAlign="center">Contact Us</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuClick("/termsofservice")}>
                                <Typography textAlign="center">Terms of Service</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ my: 2, flexGrow: 1, alignItems: 'center', justifyContent: 'center', display: { xs: "flex", md: "none" }}}>
                            <img src={logo} height="55px" width="auto" alt="Logo" />
                    </Box>
                    <Box sx={{ flexGrow: 1, justifyContent: 'space-between', display: { xs: "none", md: "flex" } }}>
                        <Box sx={{ my: 2, alignItems: 'center', display: { xs: "none", md: "flex" }}}>
                            <img src={logo} height="55px" width="auto" alt="Logo" />
                        </Box>
                        <Box sx={{ justifyContent: 'center', display: { xs: "none", md: "flex" } }}>
                            <Button onClick={() => handleMenuClick("/")} sx={{ my: 2, color: "white", display: "block" }}>Sign Up</Button>
                            <Button href="https://www.instagram.com/refundio/" target="_blank" sx={{ my: 2, color: "white" }}>Instagram</Button>
                            <Button onClick={() => handleMenuClick("/faq")} sx={{ my: 2, color: "white", display: "block" }}>FAQ</Button>
                            <Button onClick={() => handleMenuClick("/contact")} sx={{ my: 2, color: "white", display: "block" }}>Contact Us</Button>
                            <Button onClick={() => handleMenuClick("/termsofservice")} sx={{ my: 2, color: "white", display: "block" }}>Terms of Service</Button>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default withRouter(ResponsiveAppBar);