import React from 'react';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';

const Footer = () => {
    return (
        <div>
            <Container maxWidth="xl">
                <Grid container direction="column" alignItems="center">
                    <Typography variant="body1">Refundio, LLC</Typography>
                    <Typography variant="body1">Patent Pending</Typography>
                </Grid>
            </Container>
        </div>
    );
}

export default Footer;